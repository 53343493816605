.ActivityGraph {
  flex-grow: 1;
}

.ActivityGraph > svg {
  background-color: rgb(241, 243, 242);
}

.ActivityGraph text {
  // font-family: 'Quicksand', sans-serif;
  font-size: 0.875rem;
}

.graphLegendItem {
  display: inline-block;
  padding: 4px;
}

.graphLegendIndicator {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 3px;
}

.graphButton {
  display: inline-block;
  width: 30px;
  height: 18px;
  background-color: darkred;
  // font-family: 'Quicksand', sans-serif;
  font-size: 0.875rem;
}
