$breakpoint-tablet: 768px;

.active-a {
  min-height: 100px;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.active-a-nocontent {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active-a-header {
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }
}

.active-a-header-text {
  padding: 0px 20px;
  flex: 1; 
}

.active-a-actions {
  display: flex;
  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }
}

.active-a-actions > div {
  width: 100%;
}

.active-a-heading {
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 20px;
}

.active-a-text {
  margin-bottom: 15px;
}

.active-a-actions-radio {
  margin-bottom: 16;
  font-size: 16px;
}