.fallback-container {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
}

.fallback-container .fallback-fill {
    color: black;
    background-color: white;
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: none;
    align-items: center;
    justify-content: center;
}

.fallback-container .fallback-fill .fallback-content {
    width:100%;
    display: block;
    text-align: center;
}

.fallback-container .fallback-fill .fallback-content > span {
    line-height: 5vh;
    font-size: 5vh;
}