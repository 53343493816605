@import '../../../styles/colors';
@import '../../../styles/fonts';

.parentContainer {
  height: 100%;  
  background-size: cover !important;         /* Generic*/
  background: #e0204d;
  background-image: url("../../../static/background.jpg");
  background-repeat: no-repeat;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.contentContainer {
  width: 60%;
  height: 60%;
  border-radius: 5px;
  display: flex;  
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.badgeGroupContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.contentLeftColumn {
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contentRightColumn {
  margin-left: 20px;
  width: 40%;
  height: 100%;
}


.badgeContainer {
  width: 100%;
}

.screenshot {
  max-width: 100%;
  max-height: 100%;
}

.appleBadge {
  margin:6%;
  width:88%;
}

.googleBadge{
  width: 100%;
}

.textContainer {
  text-align: center;
  width: 100%;
  margin-bottom: 50px;
  font-size: 25px;
  line-height: 32px;
  color: rgb(238, 238, 238);
}

.supportMessageText {
  font-size: 20px;
  line-height: 25px;
}

.supportMessageText h2,
.textContainer h2 {
  font-size: 50px;
}

.supportMessageText p, 
.textContainer p {
  margin-top: 30px;
}

.supportMessageText a,
.textContainer a {
  color: white;
  font-weight: bold;
}

/* unvisited link */
.supportMessageText a:link, 
.textContainer a:link {
  color: white;
  font-weight: bold;
}

/* visited link */
.supportMessageText a:visited,
.textContainer a:visited {
  color: white;
}

/* mouse over link */
.supportMessageText a:hover,
.textContainer a:hover {
  color: grey;
}

/* selected link */
.supportMessageText a:active,
.textContainer a:active {
  color: grey;
  font-weight: bold;
}

.logoutButton span {
  font-size: 20px !important;
}

@media(max-width:769px){
  .contentContainer {
    width: 80%;  
  }

  .parentContainer {
    background-image: none;
  }

  .contentLeftColumn{
    width: 100%;
  }

  .contentRightColumn{
    display: none;
  }

  .textContainer {
    font-size: 28px;
    line-height: 35px;
  }

  .supportMessageText {
    font-size: 25px;
    line-height: 35px;
  }

  .supportMessageText h2,
  .textContainer h2 {
    font-size: 50px;
  }
}

@media(max-width:480px) {
  .contentContainer {
    width: 80%;  
    height: 90%;
  }

  .parentContainer {
    background-image: none;
  }

  .contentRightColumn{
    display: none;
  }

  .badgeGroupContainer {
    flex-direction: column !important;

  }

  .textContainer {
    font-size: 20px;
    line-height: 26px;
  }

  .supportMessageText h2,
  .textContainer h2 {
    font-size: 34px;
  }

  .supportMessageText {
    font-size: 17px;
    line-height: 26px;
  }
}