.ic-brand {
  background-color: #E0204D !important;
}

.ic-alert {
  background-color: #CC1F25 !important;
}

.ic-amber {
  background-color: #F26A34 !important;
}

.ic-okay {
  background-color: #8AC56D !important;
}

.ic-normal {
  background-color: #004E80 !important;
}

.ic-complementary-purple {
  background-color: #2E1250 !important;
}

.ic-primary-text {
  color: #212121 !important;
}

.ic-secondary-text {
  color: #757575 !important;
}
