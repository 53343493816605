.brand-loader {
  flex: 1;
  position: relative;
}

.brand-loader--abs-center {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
}

.brand-loader--heart {
  animation: pulse 2s linear;
  animation-iteration-count: infinite;
}

@keyframes pulse {
 0% {
    transform: scale(1);
 }
 50% {
    transform: scale(1.1);
 }
 100% {
    transform: scale(1);
 }
}
