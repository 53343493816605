.OverallActivityGraph {
  flex-grow: 1;
}

.OverallActivityGraph > svg {
  background-color: rgb(241, 243, 242);
}

.OverallActivityGraph text {
  // font-family: 'Quicksand', sans-serif;
  font-size: 0.875rem;
}
