.dailyActivityGraph text {
  font-size: 1rem;
}

.popup {
  display: block;
  visibility: hidden;
  position: absolute;
  color: white;
  background: grey;
  border: solid 2px #ddd;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  width: 200px;
  z-index: 1000;
}

.fadeout {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 200ms, opacity 200ms;
}

.fadein {
  opacity: 1;
  visibility: visible;
  transition: visibility 0s linear 0s, opacity 300ms;
}

@keyframes fadePopup {
  0% { opacity: 0};
  100% { opacity: 1};
}
