$breakpoint-phone: 420px;

.household .content--container-content {
  padding-top: 0px;
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  zoom: 0.85;
  @media (max-width: $breakpoint-phone) {
    margin-top: 20px;
  }
}

.household--loader {
  margin-top: 140px;
}

.household--tabs {
  width: 100%;
}

.household--graph-title {
  margin-top: 25px;
}

.household--tabs > div {
  background: white !important;
}

.household--tabs-item-content {
  display: flex;
  height: 450px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
}

.household--tabs-item {
  color: black !important;
}

.household--detail-row {
  background-color: white;
}
.household--detail-row-heading {
  margin-bottom: 15px;
}
.household--detail-graph-heading {
  margin-bottom: 15px;
  margin-top: 20px;
}

.scroll-chunk {
  height: 250px;
  overflow-y: scroll;
  overflow: overlay;
  @media (max-width: $breakpoint-phone) {
    height: auto;
  }
}

.main-chunk {
  min-height: 400px;
}
