.NightGraph {
    flex-grow: 1;
}

.NightGraph>svg {
    background-color: rgb(241, 243, 242);
}

.NightGraph text {
    // font-family: 'Quicksand', sans-serif;
    font-size: 0.875rem;
}

.NightGraphLabel {
    display: inline-block;
    padding: 4px;
}

.graphLegendIndicator {
    width: 12;
    height: 12;
    border-radius: 3;
    display: inline-block;
}
