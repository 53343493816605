.ActivityGraph {
  flex-grow: 1;
}

.ActivityGraph > svg {
  background-color: rgb(255, 255, 255);
}

.ActivityGraph text {
  font-size: 0.875rem;
}

.graphLegendItem {
  padding-left: 10px;
}

.graphLegendIndicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 3px;
}

.graphButton {
  display: inline-block;
  width: 30px;
  height: 18px;
  background-color: darkred;
  // font-family: 'Quicksand', sans-serif;
  font-size: 0.875rem;
}
