$breakpoint-tablet: 768px;
$breakpoint-phone: 420px;

.cstrap {
  background: rgb(176, 190, 197);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: 60px;
  width: 100%;
  overflow: hidden;
  position: relative;
  top: -1px;
  
  @media (max-width: $breakpoint-tablet) {
    padding: 40px 10px
  }
  @media (max-width: $breakpoint-phone) {
    padding: 20px 10px 10px 10px;
  }
}

.cstrap--heading {
  padding-left: 20px;
  flex: 1;
  min-width: 320px;
  flex-basis: 320px; 
}

.cstrap--heading-title {
  font-size: 26px;
  padding-bottom: 10px;
  color: white;
  font-weight: normal;
}
.cstrap--heading-subtitle {
  font-size: 16px;
  color: white;
  font-weight: normal;
}


.cstrap--actions {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  min-width: 320px;
  flex-basis: 320px; 
  margin-right: 30px;

  @media (max-width: $breakpoint-tablet) {
    justify-content: flex-start;
    margin-right: 0px;
  }
}

.cstrap--actions-graph {
  flex-grow: 1;
}