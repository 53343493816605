
.active-as {
  display: flex;
  flex: 1;
}

.active-as--overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100px;
  width: 100px;
  background: white;
  border-radius: 50px;
  display: flex;
  opacity: 0;
}
.active-as--overlay-inner {
  display: flex;
  flex: 1;
  padding: 20px;
}
.active-as--overlay.minimised {
  opacity: 1;
}

.active-as-dialog {
  transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms,
              padding 500ms cubic-bezier(0.23, 1, 0.32, 1) 0ms !important;
  padding-top: 16px !important;
}
// Only way I could get a reference to this div.
.active-as-dialog > div {
  transition: width 500ms cubic-bezier(0.23, 1, 0.32, 1) 0ms !important;
  position: absolute;
  width: 100%;
}

.active-as-dialog.minimise > div {
  width: 100px;
}

.active-as-dialog.minimise {
  height: 0px !important;
  padding-top: 0px !important;
}

.active-as-dialog--overlay {
  transition: height 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.active-as-dialog--overlay.minimise {
  height: 0px !important;
}

.active-as-dialog--content {
  transition: height 500ms width 500ms top 500ms transform 500ms border-radius 500ms;
}
.active-as-dialog--content.minimise {
  height: 100px !important;
  width: 100px !important;
  top: 50px;
  overflow: hidden;
  transform: translate(calc(70vw), -30px) !important;
  -moz-transform: translate(calc(70vw), -30px) !important;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.156863) 3px 10px 5px, rgba(0, 0, 0, 0.327451) 3px 10px 5px;
  border: 1px solid white;
}

.active-as-dialog > div > div > div {
  flex: 1;
}

.active-as-dialog--content > div {
  display: flex;
  flex-direction: column;
}

.active-as-list {
  flex: 1;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

// .active-as-list--scroller {
//   overflow-y: scroll;
//   overflow-x: hidden;
//   height: 100%;
// }

// .active-as-list--item {
//   padding: 15px;
//   border-bottom: 1px solid rgba(0, 0, 0, 0.2);
//   background: white;
// }

// .active-as-list--item.active {
//   background: #CC1F25;
//   color: white;
// }

// .active-as-list--item-name {
//   margin: 0px;
//   font-weight: bold;
// }
// .active-as-list--item-time {
//   margin: 0px;
// }
// .active-as-list--item-text {
//   margin: 0px;
// }

.active-as-content {
  flex: 3;
  padding: 15px;
  overflow-y: scroll;
}

.minimised-modal {
  overflow: auto !important;
}
